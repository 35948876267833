import React, { useState } from 'react';
import { getText } from '@/common/language';
import VerticalBook from '@/components/VerticalBook';
import HorizontalBook from '@/components/HorizontalBook';
import { queryRecList } from '@/service/book';
import { useAsyncEffect } from '@/common/hook';
import { getWordCount } from '@/common/util';

import styels from './index.module.less';
import { IBook } from '@/types/book';
import Loading from '@/components/Loading';

import bar from '@/static/bar.png';
import { replacePage } from '@/common/router';

interface ISuggestBook {
  1: IBook[];
  2: IBook[];
  3: IBook[];
  4: IBook[];
  5: IBook[];
}

export default function Suggest(params: { workDirection: string }) {
  const { workDirection } = params;
  const [recBook, setRecBook] = useState<ISuggestBook | null>(null);

  // 查推荐
  useAsyncEffect(async () => {
    const result = await queryRecList(workDirection);
    if (!result) return;
    setRecBook(result);
  }, [workDirection]);

  const handleBookInfo = (data: IBook) => {
    replacePage(`./book.html?bookId=${data?.id || data?.bookId}`);
  };

  if (!recBook) return <Loading />;

  return (
    <div className={styels.suggestMoudle}>
      {recBook[1]?.[0]?.topPicUrl && (
        <header
          onClick={() => {
            handleBookInfo(recBook[1][0]);
          }}
          className={styels.header}
        >
          <img src={recBook[1][0].topPicUrl} alt="" />
        </header>
      )}
      {/* 主编推荐 */}
      {recBook[2]?.length && (
        <section className={styels.suggest}>
          <span className={styels.suggestTitle}>{getText('主编推荐')}</span>
          <VerticalBook data={recBook[2][0]} />
          <div className={styels.suggestBooks}>
            {recBook[2]
              ?.slice(1, 4)
              .map((item) => <HorizontalBook key={item.id} data={item} />)}
          </div>
        </section>
      )}
      {/* 精品推荐 */}
      {!!recBook[3]?.length && (
        <section className={styels.quality}>
          <div className={styels.qualityHead}>
            <div className={styels.qualityTitle}>{getText('精品推荐')}</div>
            {/* <div className={styels.more}>{getText('查看全部')}</div> */}
          </div>
          <div className={styels.qualityList}>
            <div className={styels.qualityListVertical}>
              {' '}
              {recBook[3]
                ?.slice(0, 3)
                ?.map((book) => <VerticalBook key={book.id} data={book} />)}
            </div>
            <div>
              {' '}
              {recBook[3]
                ?.slice(3, 6)
                ?.map((book) => <VerticalBook key={book.id} data={book} />)}
            </div>
            <div>
              {' '}
              {recBook[3]
                ?.slice(6, 9)
                ?.map((book) => <VerticalBook key={book.id} data={book} />)}
            </div>
          </div>
        </section>
      )}
      {/* 新书优选 */}
      {!!recBook[4]?.length && (
        <section className={`${styels.quality} ${styels.new}`}>
          <div className={styels.qualityHead}>
            <div className={styels.qualityTitle}>{getText('新书优选')}</div>
            {/* <div className={styels.more}>{getText('查看全部')}</div> */}
          </div>
          <div className={styels.qualityList}>
            <div>
              {' '}
              {recBook[4]
                ?.slice(0, 3)
                ?.map((book) => <VerticalBook key={book.id} data={book} />)}
            </div>
            <div>
              {' '}
              {recBook[4]
                ?.slice(3, 6)
                ?.map((book) => <VerticalBook key={book.id} data={book} />)}
            </div>
            <div>
              {' '}
              {recBook[4]
                ?.slice(6, 9)
                ?.map((book) => <VerticalBook key={book.id} data={book} />)}
            </div>
          </div>
        </section>
      )}
      {/* 完本精选 */}
      {!!recBook[5]?.length && (
        <section className={styels.end}>
          <div className={styels.endHead}>
            <div className={styels.endTitle}>{getText('完本优选')}</div>
            {/* <div className={styels.more}>{getText('查看全部')}</div> */}
          </div>
          <div className={styels.endBook}>
            {recBook[5].slice(0, 3).map((book) => (
              <HorizontalBook key={book.id} data={book} />
            ))}
          </div>
        </section>
      )}
      {/* TODO 联系我们 */}
      <footer></footer>
    </div>
  );
}
